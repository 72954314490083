import { Link } from 'gatsby'
import React from 'react'
import { greenButtonHolder } from './Buttons.module.scss'

export function GreenButton({to, alt, children}) {
  return (
    <Link to={to} alt={alt} className={greenButtonHolder}>
      {children}
    </Link>
  )
}
