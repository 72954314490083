import React from 'react'
import { motion, MotionConfig } from 'framer-motion'
import {risingSpansHolder} from './RisingSpans.module.scss'


export default function RisingSpans({children, staggerChildren, exitAnimation, controls, ...props}) {

  const parentVars = {
    pre:{},
    vis:{
      transition: {
        staggerChildren: staggerChildren
      }
    },
    post:{
      transition: {
        staggerChildren: staggerChildren / 2
      }
    }
  }

  const spanVars = {
    pre: {
      opacity:[0,0,0],
      y:'1.5em'
    },
    vis: {
      opacity:[0,0,1],
      y: 0
    },
    post: {
      opacity:0,
      y:'-1.5em',
      transition: {
        duration:0.5
      }
    },
    pause: {
      transition: {
        delay:1
      },
      opacity: [1,0.99,1]
    }
  }

  let sections = []
  let words = []

  if(typeof children.props.children === 'string')
  sections = children.props.children.split(' ')
  else {
    sections = children.props.children
  }

  sections.forEach(child => {
    if(typeof child === 'string') {
      let tempWords = child.trim().split(' ')
      words.push(...tempWords)
    } else {
      words.push(child)
    }
  })

  let type = motion.div

  switch(children.type) {
    case 'h1':
      type = motion.h1
      break
    case 'h2':
      type = motion.h2
      break
    case 'h3':
      type = motion.h3
      break
    case 'h4':
      type = motion.h4
      break
    case 'h5':
      type = motion.h5
      break
    case 'h6':
      type = motion.h6
      break
    default :
      type = motion.div
  }





  return (
  <MotionConfig transition={{type:'tween', duration:1}}>
    {  React.createElement(
    type,
    { className:risingSpansHolder,
      variants: parentVars,
      initial: 'pre',
      animate: (controls ? controls : 'vis'),
      exit: ( exitAnimation ? 'post' : null),
      ...props
    },
    words.map((word, index) => {

    return <div className="spanHider" key={index}><motion.span variants={spanVars}>{word} </motion.span></div>
    })
    )  }
  </MotionConfig>
  )
}
