import React, {useEffect, useContext} from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GreenButton } from '../../components/components/Buttons'
import RisingSpans from '../../components/components/RisingSpans'
import {motion, MotionConfig, useAnimation, usePresence} from 'framer-motion'
import {benefitsHolder } from '../pages.module.scss'

import {SectionNavContext} from '../../components/utilities/SiteContexts'

import Air from '../../images/svgs/benefits/Air.svg'
import Car from '../../images/svgs/benefits/Car.svg'
import Graph from '../../images/svgs/benefits/Graph.svg'
import Leaf from '../../images/svgs/benefits/Leaf.svg'
import Money from '../../images/svgs/benefits/Money.svg'
import Plug from '../../images/svgs/benefits/Plug.svg'
import Tree from '../../images/svgs/benefits/Tree.svg'

const sunVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:1.5
    }
  },
  post: {
    pathLength: 0,
    transition:{
      duration:0.7
    }
  }
}

const lineVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:1
    }
  },
  post: {
    pathLength: 0
  }
}

const rhsVariants = {
  pre: {},
  vis: {
    transition: {
      staggerChildren:0.1
    }
  },
  post: {
    transition: {
      staggerChildren:0.1
    }
  }
}

const lhsVariants = {
  pre: {
    opacity:0,
    x:'-20rem'
  },
  vis: {
    opacity:1,
    x:0,
    transition: {
      duration:2
    }
  },
  post: {
    opacity:0,
    x:'-20rem'
  }
}

const liVariants = {
  pre: {
    opacity:0,
    x:'-2rem'
  },
  vis: {
    opacity:1,
    x:0
  },
  post: {
    opacity:0,
    x:'2rem'
  }
}


export default function BenefitsPage() {

  const lhsControl = useAnimation()
  const rhsControl = useAnimation()
  const headerControl = useAnimation()
  const sunControl = useAnimation()
  const lineControl = useAnimation()

  const [isPresent, safeToRemove] = usePresence()


  const [,setSectionNav] = useContext(SectionNavContext)


    const introAnimation = async () => {
      sunControl.start('vis')
      lhsControl.start('vis')
      await headerControl.start('vis')
      rhsControl.start('vis')
      lineControl.start('vis')
  }



  useEffect(() => {
    introAnimation()
    setSectionNav([
      {
        to: '/',
        title: 'Home'
      },
      {
        to: '/about/video',
        title: 'Video'
      },
      { 
        to: '/about/benefits',
        title: 'Benefits',
        active:true
      },
      {
        to: '/about/expertise',
        title: 'Expertise',
      },
      {
        to: '/about/achievements',
        title: 'Achievements'
      },
      {
        to: '/about/contact',
        title: 'Contact'
      }
    ])
  }, [])

  useEffect(() => {
    const exitAnimation = async () => {
      lineControl.start('post')
      rhsControl.start('post')
      headerControl.start('post')
      lhsControl.start('post')
      await sunControl.start('post')
      safeToRemove()
    }


    if(!isPresent) {
      exitAnimation()
    }
  }, [isPresent, lineControl, rhsControl, headerControl, lhsControl, sunControl, safeToRemove])




  return (
    <article className={benefitsHolder}>
      <MotionConfig transition={{type:'tween'}}>
      <div className="lrHolder">
        <motion.div animate={lhsControl} variants={lhsVariants} initial="pre" className="lhs">
          <StaticImage
            src="../../images/about/benefits.jpg"
            alt="Benefits of CCS"
            objectPosition="50% 100%"
          />
        </motion.div>

        <motion.div animate={rhsControl} variants={rhsVariants} className="rhs">
          <RisingSpans staggerChildren={0.3} controls={headerControl} exitAnimation={false}><h1>We help our clients:</h1></RisingSpans>
          <motion.ul variants={rhsVariants} animate={rhsControl} initial="pre">
            <motion.li variants={liVariants}><Tree />Meet carbon reduction targets.</motion.li>
            <motion.li variants={liVariants}><Plug />Unlock EV charging points for public & staff.</motion.li>
            <motion.li variants={liVariants}><Car />Move your fleets to electric vehicles.</motion.li>
            <motion.li variants={liVariants}><Air />Meet air quality targets.</motion.li>
            <motion.li variants={liVariants}><Money />Significantly reduce energy costs.</motion.li>
            <motion.li variants={liVariants}><Graph />Protect against future energy cost rises.</motion.li>
            <motion.li variants={liVariants}><Leaf />Demonstrate green credentials.</motion.li>
          </motion.ul>

          <motion.div variants={liVariants} animate={rhsControl} initial="pre">
            <GreenButton to="/about/expertise" alt="Our areas of expertise">
              Find out how
            </GreenButton>
          </motion.div>

          <svg className="dark" width="493" height="2" viewBox="0 0 493 2" fill="none" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path animate={lineControl} initial="pre" variants={lineVariants} d="M0.5 1H493" stroke="currentColor" stroke-width="2"/>
          </svg>
        </motion.div>
      </div>


      <motion.div className="sun">
        <svg className="dark" width="407" height="407" viewBox="0 0 407 407" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path animate={sunControl} initial="pre" variants={sunVariants} d="M405.5 203.5C405.5 315.062 315.062 405.5 203.5 405.5C91.9385 405.5 1.5 315.062 1.5 203.5C1.5 91.9385 91.9385 1.5 203.5 1.5C315.062 1.5 405.5 91.9385 405.5 203.5Z" stroke="currentColor" stroke-width="3"/>
        </svg>
      </motion.div>

     </MotionConfig>
    </article>
  )
}
